import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { Layout } from "../Layout";

export const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Typography variant="h2">{t("NotFound.notFound")}</Typography>
    </Layout>
  );
};
